@layer modules, ui, base;
@layer base {
  .PublicationDateFilter_options__KTsJy {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: var(--sk-space-16);
}

@media (max-width: 767px) {
  .PublicationDateFilter_options__KTsJy {
    grid-template-columns: 1fr;
  }
}

}
@layer base {
  .SvgImage_main__oZ4ze {
  /* 1x1 pixel blank transparent GIF image */
  content: url("data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==");
  background-color: currentcolor;
}
}
@layer base {
  .UploadFile_container__TsZsF {
  display: none;
}

.UploadFile_container___visible__pDrwM {
  display: block;
}

.UploadFile_main__5MeOi {
  display: none;
  position: relative;
  padding: var(--sk-space-24);
  border-radius: var(--sk-radius-16);
  background-color: var(--sk-color-grey-050);
  text-align: center;
}

.UploadFile_main___visible__7q9kV {
  display: block;
}

.UploadFile_main___dragOver__15kn8 {
  background-color: var(--sk-color-grey-100);
}

.UploadFile_overtitle__HJOjo {
  margin-bottom: var(--sk-space-16);
}

.UploadFile_icon__OuSn0 {
  margin-bottom: var(--sk-space-24);
  background-color: var(--sk-color-grey-100);
}

.UploadFile_fileInfo__N32ex {
  margin-top: var(--sk-space-24);
  color: var(--sk-color-grey-500);
}

.UploadFile_description__fPb2M {
  margin-top: var(--sk-space-4);
}

.UploadFile_uploadButton__c_ODS {
  position: relative;
  z-index: var(--z-up-in-the-current-stacking-context);
  width: 100%;
  margin-top: var(--sk-space-16);
}

/* This prevents to drop a file on the button */
.UploadFile_main___dragOver__15kn8 .UploadFile_uploadButton__c_ODS {
  z-index: var(--z-reset-in-the-current-stacking-context);
}

/* The input needs to be above the whole component to accept dropped files */
.UploadFile_uploadButton__input__0icHO {
  position: absolute;
  z-index: var(--z-reset-in-the-current-stacking-context);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.UploadFile_error__WgfNl {
  margin-top: var(--sk-space-8);
  color: var(--sk-color-error-500);
}

}
@layer base {
  .CoverLetterContent_messageField__xbRht {
  box-sizing: border-box;
  width: 100%;
  padding: var(--sk-space-12) var(--sk-space-4) var(--sk-space-12) var(--sk-space-16);
  border: 1px solid var(--sk-color-grey-300);
  border-radius: var(--sk-radius-8);
  font-family: var(--sk-font-families-poppins);
  font-size: var(--sk-font-size-2);
  resize: vertical;
}

.CoverLetterContent_messageField__xbRht:focus {
  outline: 2px solid var(--sk-color-grey-500);
  outline-offset: -1px;
}

.CoverLetterContent_messageLabel__NdxVy {
  margin: var(--sk-space-16) 0 var(--sk-space-4);
}

.CoverLetterContent_messageLength__7kB5u {
  color: var(--sk-color-grey-500);
  text-align: end;
}

.CoverLetterContent_error__fV6op {
  margin-top: var(--sk-space-8);
  color: var(--sk-color-error-500);
}

.CoverLetterContent_warning__pQZaE {
  margin-top: var(--sk-space-8);
  color: var(--sk-color-warning-500);
}

}
@layer ui {
  .sk-Tooltip_main__M4c_q {
  --sk-Tooltip-arrow-size: 7px;
  --sk-Tooltip-tooltip-distance-horizontal: 0.5rem;
  --sk-Tooltip-tooltip-distance-vertical: 1.5rem;
  --sk-Tooltip-arrow-border-distance: 10px;
  --sk-Tooltip-text-spacing: var(--sk-space-4);
  --sk-Tooltip-icon-gap: var(--sk-space-4);
  --sk-Tooltip-spacing-horizontal: 0px;
  --sk-Tooltip-spacing-vertical: 0px;

  display: inline-flex;
  position: relative;
  /* !important is set to force the usage of the CSS variables */
  padding: var(--sk-Tooltip-spacing-vertical) var(--sk-Tooltip-spacing-horizontal) !important;
  border: none;
  border-radius: var(--sk-radius-2);
  outline: none;
  background: none;
  cursor: pointer;
  gap: calc(var(--sk-Tooltip-text-spacing) + var(--sk-Tooltip-icon-gap));
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
}

.sk-Tooltip_main__M4c_q:focus-visible {
  box-shadow: var(--sk-ressource-focus-ring-shadow-dark-shadow-3), var(--sk-ressource-focus-ring-shadow-dark-shadow-2),
    var(--sk-ressource-focus-ring-shadow-dark-shadow-1);
}

/* Arrow */
.sk-Tooltip_main__M4c_q::before {
  position: absolute;
  width: 0;
  height: 0;
}

.sk-Tooltip_is-active__4Rm_U::before {
  content: "";
}

@media (pointer: fine) {
  .sk-Tooltip_main__M4c_q:not(.sk-Tooltip_is-active__4Rm_U):focus::before,
  .sk-Tooltip_main__M4c_q:not(.sk-Tooltip_is-active__4Rm_U):active::before,
  .sk-Tooltip_main__M4c_q:not(.sk-Tooltip_is-active__4Rm_U):hover::before,
  .sk-Tooltip_main__M4c_q:not(.sk-Tooltip_is-active__4Rm_U):focus-visible::before {
    content: "";
    animation: sk-Tooltip_fadeIn__QjvGD 100ms ease-out;
  }
}

@media (pointer: coarse) {
  .sk-Tooltip_with-touch__W860v:not(.sk-Tooltip_is-active__4Rm_U):focus::before,
  .sk-Tooltip_with-touch__W860v:not(.sk-Tooltip_is-active__4Rm_U):active::before {
    content: "";
    animation: sk-Tooltip_fadeIn__QjvGD 100ms ease-out;
  }
}

/* Message */
.sk-Tooltip_main__M4c_q::after {
  position: absolute;
  width: -moz-max-content;
  width: max-content;
  max-width: var(--sk-overlay-tooltip-text-size-max-width-horizontal);
  padding: 8px 12px;
  border-radius: var(--sk-overlay-tooltip-radius);
  font-family: var(--sk-typography-overlay-tooltip-text-typography-font-family);
  font-size: var(--sk-typography-overlay-tooltip-text-typography-font-size);
  font-weight: var(--sk-typography-overlay-tooltip-text-typography-font-weight);
  line-height: var(--sk-typography-overlay-tooltip-text-typography-line-height);
  text-align: left;
}

.sk-Tooltip_is-active__4Rm_U::after {
  content: attr(data-content);
}

@media (pointer: fine) {
  .sk-Tooltip_main__M4c_q:not(.sk-Tooltip_is-active__4Rm_U):focus::after,
  .sk-Tooltip_main__M4c_q:not(.sk-Tooltip_is-active__4Rm_U):active::after,
  .sk-Tooltip_main__M4c_q:not(.sk-Tooltip_is-active__4Rm_U):hover::after,
  .sk-Tooltip_main__M4c_q:not(.sk-Tooltip_is-active__4Rm_U):focus-visible::after {
    content: attr(data-content);
    animation: sk-Tooltip_fadeIn__QjvGD 100ms ease-out;
  }
}

@media (pointer: coarse) {
  .sk-Tooltip_with-touch__W860v:not(.sk-Tooltip_is-active__4Rm_U):focus::after,
  .sk-Tooltip_with-touch__W860v:not(.sk-Tooltip_is-active__4Rm_U):active::after {
    content: attr(data-content);
    animation: sk-Tooltip_fadeIn__QjvGD 100ms ease-out;
  }
}

/* Theme */
.sk-Tooltip_theme--light__aIqq1 {
  background-color: var(--sk-overlay-tooltip-trigger-color-background-default-light);
  color: var(--sk-overlay-tooltip-trigger-color-default-light);
}

.sk-Tooltip_theme--dark__I5vib {
  background-color: var(--sk-overlay-tooltip-trigger-color-background-default-dark);
  color: var(--sk-overlay-tooltip-trigger-color-default-dark);
}

.sk-Tooltip_theme--light__aIqq1:focus-visible {
  background-color: var(--sk-overlay-tooltip-trigger-color-background-focus-light);
  color: var(--sk-overlay-tooltip-trigger-color-focus-light);
}

.sk-Tooltip_theme--dark__I5vib:focus-visible {
  background-color: var(--sk-overlay-tooltip-trigger-color-background-focus-dark);
  color: var(--sk-overlay-tooltip-trigger-color-focus-dark);
}

.sk-Tooltip_theme--light__aIqq1::before {
  color: var(--sk-overlay-tooltip-color-background-light);
}

.sk-Tooltip_theme--light__aIqq1::after {
  background-color: var(--sk-overlay-tooltip-color-background-light);
  color: var(--sk-overlay-tooltip-color-light);
}

.sk-Tooltip_theme--dark__I5vib::before {
  color: var(--sk-overlay-tooltip-color-background-dark);
}

.sk-Tooltip_theme--dark__I5vib::after {
  background-color: var(--sk-overlay-tooltip-color-background-dark);
  color: var(--sk-overlay-tooltip-color-dark);
}

/* Position */
/* Position: vertical */
.sk-Tooltip_position--top__5jQJQ::before,
.sk-Tooltip_position--bottom__0y19i::before {
  margin-left: 50%;
  transform: translateX(calc(-50% - var(--sk-Tooltip-spacing-horizontal)));
  border-right: var(--sk-Tooltip-arrow-size) solid transparent !important;
  border-left: var(--sk-Tooltip-arrow-size) solid transparent !important;
}

.sk-Tooltip_position--top__5jQJQ::after,
.sk-Tooltip_position--bottom__0y19i::after {
  margin-left: 50%;
}

.sk-Tooltip_position--top__5jQJQ::before {
  bottom: calc(var(--sk-Tooltip-tooltip-distance-vertical) + var(--sk-Tooltip-spacing-vertical) * 2);
  border-top: var(--sk-Tooltip-arrow-size) solid currentcolor;
}

.sk-Tooltip_position--top__5jQJQ::after {
  bottom: calc(
    var(--sk-Tooltip-tooltip-distance-vertical) + var(--sk-Tooltip-spacing-vertical) * 2 +
      var(--sk-Tooltip-arrow-size) - 1px
  );
}

.sk-Tooltip_position--bottom__0y19i::before {
  top: calc(var(--sk-Tooltip-tooltip-distance-vertical) + var(--sk-Tooltip-spacing-vertical) * 2);
  border-bottom: var(--sk-Tooltip-arrow-size) solid currentcolor;
}

.sk-Tooltip_position--bottom__0y19i::after {
  top: calc(
    var(--sk-Tooltip-tooltip-distance-vertical) + var(--sk-Tooltip-spacing-vertical) * 2 +
      var(--sk-Tooltip-arrow-size) - 1px
  );
}

/* Position: horizontal */
.sk-Tooltip_position--left__jheFL::before,
.sk-Tooltip_position--right__VwXCY::before,
.sk-Tooltip_position--left__jheFL::after,
.sk-Tooltip_position--right__VwXCY::after {
  top: -50%;
}

.sk-Tooltip_position--left__jheFL::before,
.sk-Tooltip_position--right__VwXCY::before {
  transform: translate(
    -50%,
    calc(-50% + var(--sk-typography-body-2-regular-line-height) + var(--sk-Tooltip-spacing-vertical) * 2)
  );
  border-top: var(--sk-Tooltip-arrow-size) solid transparent !important;
  border-bottom: var(--sk-Tooltip-arrow-size) solid transparent !important;
}

.sk-Tooltip_position--left__jheFL::before,
.sk-Tooltip_position--left__jheFL::after {
  margin-right: 50%;
}

.sk-Tooltip_position--left__jheFL::before {
  /* arbitrary 3px due to offset inconstency */
  right: calc(50% + var(--sk-Tooltip-tooltip-distance-horizontal) + var(--sk-Tooltip-spacing-horizontal) * 2 - 3px);
  border-left: var(--sk-Tooltip-arrow-size) solid currentcolor;
}

.sk-Tooltip_position--left__jheFL::after {
  /* arbitrary 1px due to offset inconstency */
  right: calc(
    50% + var(--sk-Tooltip-tooltip-distance-horizontal) + var(--sk-Tooltip-spacing-horizontal) * 2 +
      var(--sk-Tooltip-arrow-size) - 1px
  );
}

.sk-Tooltip_position--right__VwXCY::before,
.sk-Tooltip_position--right__VwXCY::after {
  margin-left: 50%;
}

.sk-Tooltip_position--right__VwXCY::before {
  left: calc(50% + var(--sk-Tooltip-tooltip-distance-horizontal) + var(--sk-Tooltip-spacing-horizontal) * 2);
  border-right: var(--sk-Tooltip-arrow-size) solid currentcolor;
}

.sk-Tooltip_position--right__VwXCY::after {
  /* arbitrary 3px due to offset inconstency */
  left: calc(50% + var(--sk-Tooltip-tooltip-distance-horizontal) + var(--sk-Tooltip-spacing-horizontal) * 2 + 3px);
}

/* Alignment */
/* Alignment: vertical */
.sk-Tooltip_position--top__5jQJQ.sk-Tooltip_align--start__onjjw::after,
.sk-Tooltip_position--bottom__0y19i.sk-Tooltip_align--start__onjjw::after {
  transform: translateX(calc(-1 * var(--sk-Tooltip-arrow-border-distance) - var(--sk-Tooltip-spacing-vertical)));
}

.sk-Tooltip_position--top__5jQJQ.sk-Tooltip_align--center__TfOgR::after,
.sk-Tooltip_position--bottom__0y19i.sk-Tooltip_align--center__TfOgR::after {
  transform: translateX(calc(-50% - var(--sk-Tooltip-spacing-horizontal)));
}

.sk-Tooltip_position--top__5jQJQ.sk-Tooltip_align--end__pJj6o::after,
.sk-Tooltip_position--bottom__0y19i.sk-Tooltip_align--end__pJj6o::after {
  transform: translateX(calc(-100% + var(--sk-Tooltip-arrow-border-distance)));
}

/* Alignment: horizontal */
.sk-Tooltip_position--left__jheFL.sk-Tooltip_align--start__onjjw::after,
.sk-Tooltip_position--right__VwXCY.sk-Tooltip_align--start__onjjw::after {
  transform: translateY(
    calc(
      var(--sk-typography-body-2-regular-line-height) + var(--sk-Tooltip-spacing-vertical) * 2 -
        var(--sk-Tooltip-arrow-border-distance)
    )
  );
}

.sk-Tooltip_position--left__jheFL.sk-Tooltip_align--center__TfOgR::after,
.sk-Tooltip_position--right__VwXCY.sk-Tooltip_align--center__TfOgR::after {
  transform: translateY(
    calc(-50% + var(--sk-typography-body-2-regular-line-height) + var(--sk-Tooltip-spacing-vertical) * 2)
  );
}

.sk-Tooltip_position--left__jheFL.sk-Tooltip_align--end__pJj6o::after,
.sk-Tooltip_position--right__VwXCY.sk-Tooltip_align--end__pJj6o::after {
  transform: translateY(
    calc(
      -100% + var(--sk-typography-body-2-regular-line-height) + var(--sk-Tooltip-spacing-vertical) * 2 +
        var(--sk-Tooltip-arrow-border-distance)
    )
  );
}

/* Animation */

@keyframes sk-Tooltip_fadeIn__QjvGD {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

}
@layer base {
  .NewCv_setCvAsDefault__NFqtA {
  margin-top: var(--sk-space-8);
}

.NewCv_setCvAsDefaultTooltip__6GGp8 {
  margin-left: var(--sk-space-8);
  vertical-align: bottom;
}

}
@layer base {
  .CvStep_header__sXUxx {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-8);
  padding: var(--sk-space-40) 0;
  text-align: center;
}

.CvStep_content__Vzu3U {
  padding-bottom: var(--sk-space-40);
}

.CvStep_footer___HDQF {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: var(--sk-space-24);
}

.CvStep_footer__button__6rf_8 {
  flex: 1 1 auto;
}

.CvStep_footer___HDQF input[type="file"] {
  display: none;
}

@media (max-width: 767px) {
  .CvStep_footer___HDQF {
    display: flex;
    position: absolute;
    right: var(--sk-space-16);
    bottom: var(--sk-space-40);
    left: var(--sk-space-16);
    flex-direction: column;
    gap: var(--sk-space-16);
  }
}

.CvStep_cvHelpText__qquA1 {
  margin-top: var(--sk-space-16); 
  color: var(--sk-color-grey-600);
}

}
@layer base {
  .Button_main__SViMi {
  border-radius: var(--sk-size-96);
  font-weight: var(--sk-typography-action-button-text-typography-m-font-weight);
  --sk-Tooltip-spacing-horizontal: var(--sk-space-16);
  --sk-Tooltip-spacing-vertical: var(--sk-space-12);
}
}
@layer base {
  .CvBox_clickableCard___h8je {
  border-color: transparent !important;
}

.CvBox_uploadButton__WnLc3 {
  width: auto;
}

.CvBox_footer__QRbn6 {
  display: flex;
  justify-content: center;
  margin-top: var(--sk-space-16);
}

.CvBox_footer__QRbn6 input[type="file"] {
  display: none;
}

}
@layer base {
  .SecondaryFilter_title__6fwaf {
  display: flex;
  gap: var(--sk-space-8);
  align-items: center;
}

.SecondaryFilter_select__DgzXL {
  margin: 0;
}

.SecondaryFilter_select__DgzXL [class*="jds-Select__control"] {
  border-radius: var(--sk-radius-8) !important;
}

.SecondaryFilter_select__DgzXL .SecondaryFilter_viewMoreButton__FsnVV {
  padding: var(--sk-space-12) var(--sk-space-16);
}

.SecondaryFilter_select__DgzXL .SecondaryFilter_viewMoreButton__FsnVV > button {
  width: 100%;
}

.SecondaryFilter_checkboxGroup__k259O {
  display: grid;
  align-items: center;
  margin: var(--sk-space-16) 0;
}

@media (min-width: 768px) {
  .SecondaryFilter_checkboxGroup__k259O {
    grid-template-columns: 1fr 1fr;
  }
}

.SecondaryFilter_checkboxGroup__choice__mvOii > label {
  width: 100%;
}

@media (max-width: 767px) {
  .SecondaryFilter_tooltip__yuZ6c {
    margin-left: auto;
  }

  .SecondaryFilter_tooltip__yuZ6c::after {
    transform: translateX(calc(-100% + var(--sk-Tooltip-arrow-border-distance)));
  }
}

@media (max-width: 379px) {
  .SecondaryFilter_tooltip__yuZ6c::after {
    max-width: calc(100vw - var(--sk-space-32) * 2);
  }
}

}
@layer ui {
  .sk-OptionTag_main__7qykx {
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  inline-size: -moz-fit-content;
  inline-size: fit-content;
  padding: var(--sk-space-4) var(--sk-space-4) var(--sk-space-4) var(--sk-space-8);
  border-radius: var(--sk-radius-12);

  font-family: var(--sk-font-families-poppins);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-regular-font-weight);
  line-height: var(--sk-typography-body-2-regular-line-height);
  gap: var(--sk-space-4);
}

.sk-OptionTag_main__7qykx:focus-visible {
  outline: 0;
  box-shadow: var(--sk-ressource-focus-ring-shadow-light-shadow-3),
    var(--sk-ressource-focus-ring-shadow-light-shadow-2), var(--sk-ressource-focus-ring-shadow-light-shadow-1);
}

.sk-OptionTag_main--read-only__iZBb4 {
  padding: var(--sk-space-4) var(--sk-space-8);
}

.sk-OptionTag_main--label__az5U3 {
  align-self: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sk-OptionTag_main--imageContainer__FMg5a {
  align-self: center;
  width: var(--sk-space-20);
  height: var(--sk-space-20);
  margin-right: var(--sk-space-4);
}

.sk-OptionTag_main--imageContainer__FMg5a > * {
  border-radius: 4px;
}

.sk-OptionTag_main--light__nWJlS {
  background-color: var(--sk-color-grey-100);
}

.sk-OptionTag_main--light__nWJlS:not(.sk-OptionTag_main--read-only__iZBb4) {
  color: var(--sk-color-black);
}

.sk-OptionTag_main--light__nWJlS.sk-OptionTag_main--read-only__iZBb4 {
  color: var(--sk-color-grey-700);
}

.sk-OptionTag_main--dark__oSAAV {
  background-color: var(--sk-color-grey-700);
}

.sk-OptionTag_main--dark__oSAAV:not(.sk-OptionTag_main--read-only__iZBb4) {
  color: var(--sk-color-white);
}

.sk-OptionTag_main--dark__oSAAV.sk-OptionTag_main--read-only__iZBb4 {
  color: var(--sk-color-grey-300);
}

}
@layer base {
  .Cv_main__ThqYm {
  margin-top: var(--sk-space-24);
}

.Cv_addNewCv__srtZn {
  width: 100%;
  margin-top: var(--sk-space-8);
}

.Cv_header__Bl9q3 {
  margin-bottom: var(--sk-space-16);
}

@media (min-width: 1312px) {
  .Cv_main__ThqYm {
    margin-top: var(--sk-space-32);
  }
}

}
@layer ui {
  .sk-InputWrapper_main__1zVBN {
  display: flex;
  flex-direction: column;
  font-family: var(--sk-font-families-poppins);
}

.sk-InputWrapper_labelWrapper__RFcXM {
  display: flex;
  gap: var(--sk-space-4);
  align-items: center;
  margin-bottom: var(--sk-space-8);
}

.sk-InputWrapper_labelWrapper--large__dv8Ff {
  margin-bottom: var(--sk-space-12);
}

.sk-InputWrapper_subLabel___4Fiu {
  font-family: var(--sk-typography-caption-1-font-family);
  font-size: var(--sk-typography-caption-1-font-size);
  font-weight: var(--sk-typography-caption-1-font-weight);
  line-height: var(--sk-typography-caption-1-line-height);
}

.sk-InputWrapper_messageWrapper__BsKEb {
  display: flex;
  justify-content: space-between;
  margin-top: var(--sk-space-8);
  gap: var(--sk-space-8);
}

.sk-InputWrapper_message__4RFam,
.sk-InputWrapper_secondaryMessage__UwXuM {
  font-family: var(--sk-typography-caption-1-font-family);
  font-size: var(--sk-typography-caption-1-font-size);
  font-weight: var(--sk-typography-caption-1-font-weight);
  line-height: var(--sk-typography-caption-1-line-height);
}

.sk-InputWrapper_secondaryMessage__UwXuM {
  margin-left: auto;
}

}
@layer base {
  .RatingInput_main__NgMba {
  --local-icon-size: var(--sk-space-24);
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-8);
}

.RatingInput_control__RKc6D {
  display: flex;
  gap: var(--sk-space-4);
}

.RatingInput_controlLabel__hGgwv {
  font-size: var(--sk-typography-body-1-regular-font-size);
  line-height: var(--sk-typography-body-1-regular-line-height);
}

.RatingInput_label__0pGWP {
  width: var(--local-icon-size);
  height: var(--local-icon-size);
  overflow: hidden;
  cursor: pointer;
}

.RatingInput_input__EY0n9 {
  position: absolute;
  width: var(--local-icon-size);
  height: var(--local-icon-size);
  margin: 0;
  padding: 0  ;
  opacity: 0;
}
}
@layer base {
  .Form_formStep__gTkns {
  display: none;
  flex-direction: column;
  gap: var(--sk-space-32);
}

.Form_formStep__visible__YyAEX {
  display: flex;
}

.Form_formStep__gTkns > .Form_title__glRFJ {
  margin-bottom: var(--sk-space-16);
}

.Form_fields__OHvuN {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-16);
}

.Form_controls__inline___rUIa {
  display: flex;
  flex-direction: row;
  gap: var(--sk-space-8);
}

.Form_control__enlarge__Sjboe {
  flex: 1;
}

.Form_control__tooltip__9cxLe {
  align-self: flex-end;
  margin-bottom: var(--sk-space-12);
}

@media (min-width: 768px) {
  .Form_ratingInput__HEHnH {
    flex-direction: row;
    justify-content: space-between;
  }
}

.Form_actions___n46h {
  display: flex;
  justify-content: flex-end;
  margin-top: var(--sk-space-40);
  gap: var(--sk-space-24);
}

@media (max-width: 767px) {
  .Form_actions___n46h {
    flex-direction: column-reverse;
    gap: var(--sk-space-16);
  }

  .Form_action__iVWAj {
    width: 100%;
  }
}
}
@layer ui {
  .sk-TextArea_textArea___8emA {
  box-sizing: border-box;
  width: 100%;
  min-height: var(--sk-size-120);
  padding: var(--sk-space-16);
  padding-right: var(--sk-space-4);
  border-width: var(--sk-form-text-area-field-size-border-width);
  border-style: solid;
  border-radius: var(--sk-form-text-area-field-radius);
  outline: none;
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-regular-font-weight);
  line-height: var(--sk-typography-body-2-regular-line-height);
  resize: vertical;
}

.sk-TextArea_textArea___8emA.sk-TextArea_textarea--no-resize__rHsJI {
  resize: none;
}

/* ON LIGHT BACKGROUND */

.sk-TextArea_main--light__Xo_kM * {
  color: var(--sk-form-text-area-color-light);
}

.sk-TextArea_main--light__Xo_kM .sk-TextArea_subLabel__fGBEF {
  color: var(--sk-form-text-area-secondary-label-color-light);
}

.sk-TextArea_main--light__Xo_kM .sk-TextArea_message__ooWpV {
  color: var(--sk-form-text-area-message-color-default-light);
}

.sk-TextArea_main--light__Xo_kM .sk-TextArea_message--error__lTRjT {
  color: var(--sk-form-text-area-message-color-error-light);
}

.sk-TextArea_main--light__Xo_kM .sk-TextArea_secondaryMessage__8fNX6 {
  color: var(--sk-form-text-area-character-count-color-default-light);
}

.sk-TextArea_main--light__Xo_kM .sk-TextArea_secondaryMessage--error__XIlIT {
  color: var(--sk-form-text-area-character-count-color-error-light);
}

.sk-TextArea_main--light__Xo_kM .sk-TextArea_textArea___8emA {
  border-color: var(--sk-form-text-area-field-color-border-default-light);
  background-color: var(--sk-form-text-area-field-color-background-default-light);
  color: var(--sk-form-text-area-field-color-default-light);
}

.sk-TextArea_main--light__Xo_kM .sk-TextArea_textArea___8emA::-moz-placeholder {
  color: var(--sk-form-text-area-field-placeholder-color-light);
}

.sk-TextArea_main--light__Xo_kM .sk-TextArea_textArea___8emA::placeholder {
  color: var(--sk-form-text-area-field-placeholder-color-light);
}

.sk-TextArea_main--light__Xo_kM.sk-TextArea_main--read-only__xwXNO .sk-TextArea_textArea___8emA {
  border-color: var(--sk-form-text-area-field-color-border-read-only-light);
  background-color: var(--sk-form-text-area-field-color-background-read-only-light);
  color: var(--sk-form-text-area-field-color-read-only-light);
  resize: none;
}

.sk-TextArea_main--light__Xo_kM.sk-TextArea_main--error__vRyIp .sk-TextArea_textArea___8emA {
  border-color: var(--sk-form-text-area-field-color-border-error-default-light);
}

.sk-TextArea_main--light__Xo_kM.sk-TextArea_main--error__vRyIp .sk-TextArea_textArea___8emA:focus {
  border-color: var(--sk-form-text-area-field-color-border-error-focus-light);
}

.sk-TextArea_main--light__Xo_kM.sk-TextArea_main--error__vRyIp .sk-TextArea_textArea___8emA:hover {
  border-color: var(--sk-form-text-area-field-color-border-error-hover-light);
}

.sk-TextArea_main--light__Xo_kM.sk-TextArea_main--error__vRyIp .sk-TextArea_textArea___8emA:active {
  border-color: var(--sk-form-text-area-field-color-border-error-active-light);
}

.sk-TextArea_main--light__Xo_kM.sk-TextArea_main--read-only__xwXNO .sk-TextArea_textArea___8emA:focus {
  border-color: var(--sk-form-text-area-field-color-border-read-only-focus-light);
  background-color: var(--sk-form-text-area-field-color-background-read-only-focus-light);
}

.sk-TextArea_main--light__Xo_kM:not(.sk-TextArea_main--read-only__xwXNO, .sk-TextArea_main--error__vRyIp) .sk-TextArea_textArea___8emA:hover {
  border-color: var(--sk-form-text-area-field-color-border-hover-light);
  background-color: var(--sk-form-text-area-field-color-background-hover-light);
}

.sk-TextArea_main--light__Xo_kM:not(.sk-TextArea_main--read-only__xwXNO, .sk-TextArea_main--error__vRyIp) .sk-TextArea_textArea___8emA:active {
  border-color: var(--sk-form-text-area-field-color-border-active-light);
  background-color: var(--sk-form-text-area-field-color-background-active-light);
}

.sk-TextArea_main--light__Xo_kM:not(.sk-TextArea_main--read-only__xwXNO, .sk-TextArea_main--error__vRyIp) .sk-TextArea_textArea___8emA:focus {
  border-color: var(--sk-form-text-area-field-color-border-focus-light);
  background-color: var(--sk-form-text-area-field-color-background-focus-light);
}

/* ON DARK BACKGROUND */

.sk-TextArea_main--dark__ZhLbb * {
  color: var(--sk-form-text-area-color-dark);
}

.sk-TextArea_main--dark__ZhLbb .sk-TextArea_subLabel__fGBEF {
  color: var(--sk-form-text-area-secondary-label-color-dark);
}

.sk-TextArea_main--dark__ZhLbb .sk-TextArea_message__ooWpV {
  color: var(--sk-form-text-area-message-color-default-dark);
}

.sk-TextArea_main--dark__ZhLbb .sk-TextArea_message--error__lTRjT {
  color: var(--sk-form-text-area-message-color-error-dark);
}

.sk-TextArea_main--dark__ZhLbb .sk-TextArea_secondaryMessage__8fNX6 {
  color: var(--sk-form-text-area-character-count-color-default-dark);
}

.sk-TextArea_main--dark__ZhLbb .sk-TextArea_secondaryMessage--error__XIlIT {
  color: var(--sk-form-text-area-character-count-color-error-dark);
}

.sk-TextArea_main--dark__ZhLbb .sk-TextArea_textArea___8emA {
  border-color: var(--sk-form-text-area-field-color-border-default-dark);
  background-color: var(--sk-form-text-area-field-color-background-default-dark);
  color: var(--sk-form-text-area-field-color-default-dark);
}

.sk-TextArea_main--dark__ZhLbb .sk-TextArea_textArea___8emA::-moz-placeholder {
  color: var(--sk-form-text-area-field-placeholder-color-dark);
}

.sk-TextArea_main--dark__ZhLbb .sk-TextArea_textArea___8emA::placeholder {
  color: var(--sk-form-text-area-field-placeholder-color-dark);
}

.sk-TextArea_main--dark__ZhLbb.sk-TextArea_main--read-only__xwXNO .sk-TextArea_textArea___8emA {
  border-color: var(--sk-form-text-area-field-color-border-read-only-dark);
  background-color: var(--sk-form-text-area-field-color-background-read-only-dark);
  color: var(--sk-form-text-area-field-color-read-only-dark);
}

.sk-TextArea_main--dark__ZhLbb.sk-TextArea_main--error__vRyIp .sk-TextArea_textArea___8emA {
  border-color: var(--sk-form-text-area-field-color-border-error-default-dark);
}

.sk-TextArea_main--dark__ZhLbb.sk-TextArea_main--error__vRyIp .sk-TextArea_textArea___8emA:focus {
  border-color: var(--sk-form-text-area-field-color-border-error-focus-dark);
}

.sk-TextArea_main--dark__ZhLbb.sk-TextArea_main--error__vRyIp .sk-TextArea_textArea___8emA:hover {
  border-color: var(--sk-form-text-area-field-color-border-error-hover-dark);
}

.sk-TextArea_main--dark__ZhLbb.sk-TextArea_main--error__vRyIp .sk-TextArea_textArea___8emA:active {
  border-color: var(--sk-form-text-area-field-color-border-error-active-dark);
}

.sk-TextArea_main--dark__ZhLbb.sk-TextArea_main--read-only__xwXNO .sk-TextArea_textArea___8emA:focus {
  border-color: var(--sk-form-text-area-field-color-border-read-only-focus-dark);
  background-color: var(--sk-form-text-area-field-color-background-read-only-focus-dark);
}

.sk-TextArea_main--dark__ZhLbb:not(.sk-TextArea_main--read-only__xwXNO, .sk-TextArea_main--error__vRyIp) .sk-TextArea_textArea___8emA:hover {
  border-color: var(--sk-form-text-area-field-color-border-hover-dark);
  background-color: var(--sk-form-text-area-field-color-background-hover-dark);
}

.sk-TextArea_main--dark__ZhLbb:not(.sk-TextArea_main--read-only__xwXNO, .sk-TextArea_main--error__vRyIp) .sk-TextArea_textArea___8emA:active {
  border-color: var(--sk-form-text-area-field-color-border-active-dark);
  background-color: var(--sk-form-text-area-field-color-background-active-dark);
}

.sk-TextArea_main--dark__ZhLbb:not(.sk-TextArea_main--read-only__xwXNO, .sk-TextArea_main--error__vRyIp, :has(.sk-TextArea_textArea___8emA:active)) .sk-TextArea_textArea___8emA:focus {
  border-color: var(--sk-form-text-area-field-color-border-focus-dark);
  background-color: var(--sk-form-text-area-field-color-background-focus-dark);
}

}
@layer base {
  /**
 * Links
 * @see https://github.com/jobteaser/spark/blob/main/packages/library/src/components/Link/sk-Link.module.css
 */
.RichText_main__L_7T3 a {
  display: inline;
  /* This property is required when Link is used inside flex parent. */
  width: -moz-fit-content;
  width: fit-content;
  border-radius: var(--sk-navigation-link-radius);
  outline: none;
  color: var(--sk-color-black);
  font-family: var(--sk-typography-navigation-link-text-typography-m-font-family), helvetica, arial, sans-serif;
  font-size: inherit;
  font-weight: var(--sk-typography-navigation-link-text-typography-m-font-weight);
  line-height: inherit;
  text-decoration: underline;
  cursor: pointer;
}

.RichText_main__L_7T3 a:hover {
  background-color: var(--sk-navigation-link-color-background-hover-light);
  color: var(--sk-navigation-link-color-hover-light);
}

.RichText_main__L_7T3 a:focus-visible {
  outline: var(--sk-navigation-link-color-border-focus-light) solid var(--sk-navigation-link-size-border-focus);
  outline-offset: 0;
}

/* Table */
.RichText_main__L_7T3 table {
  width: 100%;
  table-layout: fixed;
}

.RichText_main__L_7T3 table,
.RichText_main__L_7T3 td,
.RichText_main__L_7T3 tr {
  border-collapse: collapse;
  border: 1px solid var(--sk-color-black);
  vertical-align: top;
}

.RichText_main__L_7T3 td {
  padding: var(--sk-space-8);
}

/* HTML sized tags */
.RichText_richTextIframe__sid0P,
.RichText_richTextImg__kXSvA {
  max-width: 100%;
}
}
@layer base {
  .Form_instructionsTitle__2sQft {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--sk-space-8);
  margin-bottom: var(--sk-space-16);
}

.Form_instructionsTitle__2sQft p {
  line-break: anywhere; /* Long character chains like links break the responsive layout */
}

.Form_logo__7O5Dn {
  -o-object-fit: cover;
     object-fit: cover;
}

.Form_divider__Qo4Yn {
  margin: var(--sk-space-32) 0;
}

.Form_fields__DIlxH + .Form_fields__DIlxH {
  margin-top: var(--sk-space-16); ;
}

}
@layer base {
  /* HEADER styles */

.PageContent_section__7F_d9 {
  padding: var(--sk-space-16);
  border-radius: var(--sk-space-16);
  gap: var(--sk-space-8);
  background-color: var(--sk-color-grey-050);
}

.PageContent_request__cHckB {
  margin-top: var(--sk-space-24);
  margin-bottom: var(--sk-space-24);
}

.PageContent_contentContainer__NYouF {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
}

.PageContent_badgesContainer__8k31G {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.PageContent_date__DtZC9 {
  align-self: flex-start;
  margin-bottom: var(--sk-space-8);
}

.PageContent_duration__UkeYQ {
  align-self: flex-start;
}

.PageContent_appointmentTitle__WMJEB {
  margin-top: var(--sk-space-8);
}

.PageContent_calendarPictoContainer__clOlZ {
  display: none;
}

.PageContent_callout__UzdDX {
  margin-top: var(--sk-space-32);
  margin-bottom: var(--sk-space-24);
}

@media (min-width: 768px) {
  .PageContent_request__cHckB {
    display: none;
  }

  .PageContent_section__7F_d9 {
    margin-top: 92px;
    padding: var(--sk-space-40);
    border-radius: var(--sk-space-40);
    gap: var(--sk-space-10);
  }

  .PageContent_badgesContainer__8k31G {
    flex-direction: row;
  }

  .PageContent_date__DtZC9 {
    margin-right: var(--sk-space-12);
    margin-bottom: 0;
  }

  .PageContent_appointmentTitle__WMJEB {
    margin-top: var(--sk-space-16);
  }
  
  .PageContent_callout__UzdDX {
    margin-top: var(--sk-space-24);
    margin-bottom: var(--sk-space-40);
  }
}

@media (min-width: 768px) {
  .PageContent_section__7F_d9 {
    margin-top: 92px;
    padding: var(--sk-space-40);
    border-radius: var(--sk-space-40);
    gap: var(--sk-space-10);
  }

  .PageContent_date__DtZC9 {
    margin-right: var(--sk-space-12);
    margin-bottom: 0;
  }

  .PageContent_appointmentTitle__WMJEB {
    margin-top: var(--sk-space-16);
  }
  
  .PageContent_callout__UzdDX {
    margin-top: var(--sk-space-24);
    margin-bottom: var(--sk-space-40);
  }
}

@media (min-width: 1312px) {
  .PageContent_wideScreen__8ztY_ {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;  
  }


  .PageContent_calendarPictoContainer__clOlZ {
    display: block;
    position: absolute;
    top: -32px;
    right: -80px;
  }

  .PageContent_calendarPicto__xKmCE {
    -o-object-fit: cover;
       object-fit: cover;
    rotate: -32.04deg;
  }
}

/* BUTTONS styles */

.PageContent_buttons__2tsPl {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-8);
  padding-top: var(--sk-space-24);
  padding-bottom: var(--sk-space-24);
}

@media (min-width: 768px) {
  .PageContent_buttons__2tsPl {
    flex-direction: row;
    justify-content: center;
    gap: var(--sk-space-16);
    padding-top: var(--sk-space-24);
    padding-bottom: var(--sk-space-24);
  }
}

}
@layer base {
  .InsightPage_main__ywc39 {
  gap: var(--sk-space-40)
}

.InsightPage_title__MgAFO {
  margin-bottom: 0;
}

.InsightPage_experience__1wuaW {
  width: initial;
  padding: var(--sk-space-16);
  border-radius: var(--sk-radius-12);
  background-color: var(--sk-color-grey-050);
}

.InsightPage_divider__4gStT {
  margin: 0;
}
}
@layer base {
  .TermsOfUse_main__aaa3I {
  margin: var(--sk-space-24) 0;
}

.TermsOfUse_main__aaa3I a {
  color: var(--sk-color-grey-900);
}

@media (min-width: 1312px) {
  .TermsOfUse_main__aaa3I {
    margin: var(--sk-space-32) 0;
  }
}

}
@layer base {
  .PageLayoutAside_aside__N6yqY {
  position: fixed;
  z-index: var(--z-index-modal);
  inset: 0;
  height: 100dvh;
}

.PageLayoutAside_aside___closing__CxRdH {
  /*
    The JS relies on the `animationend` event to close the Application Flow.
    So, we need to define a no-op animation for small and medium screens.
  */
  animation: PageLayoutAside_no-op__EPd9U forwards;
}

.PageLayoutAside_aside___similarJobads__opCE_ {
  display: none;
}

@keyframes PageLayoutAside_no-op__EPd9U {
  0%, 100% {
    transform: none;
  }
}

@media (min-width: 1312px) {
  .PageLayoutAside_aside__N6yqY {
    --application-flow-width: 31vw;
    content: "displayedAsPanel"; /* used on javascript side */
    position: absolute;
    z-index: var(--z-up-in-the-current-stacking-context);
    width: var(--application-flow-width);
    max-width: 560px;
    height: 100%;
    transform: translateX(calc(-1 * var(--sk-space-24)));
    animation: PageLayoutAside_slide-in-from-right__YfPEK 0.5s;
    inset: 0 0 0 auto;
  }

  .PageLayoutAside_aside___similarJobads__opCE_ {
    display: block;
  }

  .PageLayoutAside_aside___closing__CxRdH {
    animation: PageLayoutAside_slide-out-from-right__1V44c 0.5s;
    opacity: 0;
  }

  @keyframes PageLayoutAside_slide-in-from-right__YfPEK {
    0% {
      transform: translateX(0);
      opacity: 0;
    }

    33% {
      transform: translateX(0);
      opacity: 0;
    }

    100% {
      transform: translateX(calc(-1 * var(--sk-space-24)));
      opacity: 1;
    }
  }

  @keyframes PageLayoutAside_slide-out-from-right__1V44c {
    0% {
      transform: translateX(calc(-1 * var(--sk-space-24)));
      opacity: 1;
    }

    33% {
      transform: translateX(0);
      opacity: 0;
    }

    100% {
      transform: translateX(0);
      opacity: 0;
    }
  }
}

}
